.header {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100vw;
  max-height: fit-content;
  background: #dedede;
  border-bottom: 0.25rem solid #f00;
  vertical-align: baseline;
  font-size: 1rem;
  line-height: 1.7;
  font-family: Roboto, Arial;
  transition: all 0.3s ease;
}
.logo {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0.5rem;
  height: fit-content;
  max-height: fit-content;
  cursor: pointer;
}
.logo-img {
  max-width: 15rem;
  max-height: 4rem;
}
.logo-text  {
  text-align: center;
}
.nav-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
 @media (max-width: 770px) { 
  .header { 
    font-size: 0.8rem;
    justify-content: flex-end;
  }
  .logo {
    align-items: flex-end;
    margin-right: 1rem;
  }
  .logo p {
    padding-top: 5px;
  }
  .logo-img {
      max-width: 10rem;
      max-height: 3rem;
    }
  .nav-container {
    width: 0;
  }
 }
