.container-contacts {
  flex-wrap: wrap;
}
.contacts-form {
  display: flex;
  line-height: 1.5;
}
.contacts-form > div {
  max-width: 50%;
}
.contacts-info {
  max-width: 45%;
  margin: 20px 100px 20px 20px;
  padding: 20px;
}
.frame {
  border: none;
}
@media (max-width: 1024px) {
  .contacts-form {
    flex-direction: column;
  }
  .contacts-form>div {
    max-width: 100%;
    margin: 0;
  }
}