.footer-menu ul {
  display: block;
  position: static;
  height: fit-content;
  background: transparent;
  padding: 0;
  column-gap: 0;
  row-gap: 0;
}
.footer-menu a {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.2em;
  color: rgb(87, 86, 86);
}