.layout-section {
  margin-top: 3rem;
}
.title-container {
  display: flex;
  justify-content: center;
}

.title {
  height: 100%;
  font-size: 2.125rem;
  font-weight: 600;
}

.title::after,
.title::before {
  content: '';
  width: 7.5rem;
  height: 2px;
  background: #f00;
  display: inline-block;
  margin: 10px;
}

.layout-card-cover {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.layout-card {
  max-width: 20%;
  margin: 1rem 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-content: flex-start;
}
.industry-slider-container img {
  height: 150px;
  min-width: 200px;
}

.layout-card:hover {
  transform: scale(1.1);
  transition-property: transform;
  transition-duration: 1s;
}
.span-layout-card {
  font-family: Roboto, sans-serif;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.about-container {
  margin-top: 50px;
}
.about-text-container {
  width: 90%;
  margin: auto;
}
.about-text{
  padding-top: 1.5rem;
  line-height: 2em;
  font-size: 16px;
  font-family: Roboto;
  text-align: justify;
}
.about-button {
  display: flex;
  padding: 0.3rem 1rem;
  font-size: 1rem;
  background: #f00;
  margin-top: 1rem;
  color: #FFF;
  border-radius: 1.75rem;
  transition: all .3s ease;
  outline: 0;
  border: 0;
  width: 160px;
  height: 35px;
  margin: 20px auto;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.about-button:hover {
  transform: translateY(10px);
}
.news {
  min-height: fit-content;
  width: 100%;
  overflow: hidden;
}
.slider-news-container {
  width: 100%;
  height: 20rem;
  display: flex;
  /* overflow: hidden; */
}
.inner-slider-news-container {
  display: flex;
  min-width: 100vw;
  max-height: 100%;
  transition: transform 0.5s ease-in-out;
}
.news-container {
  width: 45%;
  display: flex;
  max-height: 100%;
  border-radius: 10px;
  margin: 3rem 2.1rem;
}
.img-news {
  width: 50%;
  object-fit: cover;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.img-news:hover {
  cursor: pointer;
} 
.date-news {
  margin-top: 10px;
  width: 120px;
  min-height: 40px;
  display: inline-flex;
  border-radius: 10px;
  overflow: hidden;
  transform: translateX(-50%);
}
.date-news > div {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap
}
.day-news {
  background: #f00;
  min-width: 50%;
  color: #FFF;
  font-size: 30px;
}
.month-news {
  background: #333;
  min-width: 50%;
  color: #FFF;
}
.news-text {
  font-family: Roboto, sans-serif;
  background-color: rgb(241, 242, 242);
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.news-text-container {
  padding: 1rem;
  height: 80%;
}
.news-text-container > p {
  font-size: 1rem;
  color: #1e1e1e;
  opacity: .65;
  margin: 20px 0 25px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}
.footer {
  display: flex;
  height: 250px;
  background: #e9e9e9;
  align-items: center;
  line-height: 1rem;
}
.footer > .logo {
  width: 20%;
  display: flex;
  align-items: center;
}
.footer>.logo>.logo-img {
  width: 90%;
}
.logo>p {
  font-size: 0.8rem;
  text-align: center;
}
a {
  color: #1e1e1e;
}
.footer-contacts-container {
  width: 90%;
  height: 80%;
  display: flex;
  margin: auto;
}
.footer-contacts {
  width: 80%;
  display: flex;
  flex-direction: row;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  padding: 10px;
  line-height: 1.2em;
}
.footer-contacts>div {
  width: 60%;
}
.footer-time {
  border-right: 1px solid black;
}
.footer-menu > .menu-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer-menu > .menu-list > li {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
}
.footer-menu a  {
  height: auto;
}
.footer-contacts > div {
  margin: 10px;
}
.navigation-footer > h4 {
  padding-top: 10px;
  margin: 10px 0;
}
@keyframes fadeInUp {
  from {
    transform: translate3d(0, 200px, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 200px, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

/* .animatedFadeInUp {
  opacity: 0
} */

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

@media (max-width: 770px) {
  .title::after,
    .title::before {
      content: '';
      width: 2rem;
      height: 2px;
      background: #f00;
      display: inline-block;
      margin: 5px;
    }
    .title {
      font-weight: 400;
      font-size: 2rem;
      vertical-align: middle;
      text-align: center;
      display: flex;
      align-items: center;
    }
    .news-container {
      width: 97vw;
      margin: 5px;
    }
    .layout-card {
      min-width: 40%;
    }
    .footer {
      height: fit-content;
    }
    .footer-contacts-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    }
    .footer-contacts {
      grid-area: 1 / 1 / 3 / 3;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      width: 100%;
    }
    .footer-contacts-info {
      grid-area: 1 / 1 / 2 / 3;
    }
    .navigation-footer {
      padding: 0px 10px 10px 10px;
      grid-area: 2 / 2 / 4 / 4;
    }
    .footer-contacts>div {
      width: auto;
    }
}