/* .form-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
} */
.form-text {
  margin-top: 1rem;
  grid-area: 1 / 1 / 2 / 3;
  font-size: 25px;
  font-weight: 500;
  color: #000;
  text-align: center;
  text-transform: uppercase;
}