.industries-slider {
  overflow-x: hidden;
  display: flex;
  width: 100vw;
  margin-top: 30px;
}
.industry-slider-container {
  display: flex;
  justify-content: center;
  min-width: 100vw;
  transition: all 1s ease-out;
}
.industry-card {
  width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px;
  cursor: pointer;
}
.img-industry-card-container {
  display: flex;
  justify-content: center;
}
.img-industry-card {
  width: 250px;
}
.arrows-industries {
  display: flex;
  justify-content: space-between;
}
.arrow-industries {
  height: 50px;
  position: static;
  transform: translate(0, -270%);
}
.arrow-industries.arrow-prev {
  transform: translate(0, -270%) rotate(180deg);
  margin-left: 10px;
}
.arrow-industries.arrow-next {
  margin-right: 10px;
}
.span-industry-card {
  margin-top: 10px;
}
@media (max-width: 992px) {
  .industry-slider-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
    .industry-card {
      width: auto;
      flex-direction: column;
      align-items: center;
    }
    .img-industry-card {
      width: 300px;
    }
    .arrow-industries {
      transform: translate(0, -480%);
    }

    .arrow-industries.arrow-prev {
      transform: translate(0, -480%) rotate(180deg);
    }
}
@media (max-width: 500px) {
  .industry-slider-container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    .arrow-industries {
      transform: translate(0);
    }

    .arrow-industries.arrow-prev {
        transform: translate(0) rotate(180deg);
    }
}