.menu {
  display: flex;
  margin-right: 2.5rem;
}
.menu-list {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 3vw;
  align-items: center;
  height: 50%;
  max-width: 100%;
  /* justify-content: right; */
}
.nav-link {
  text-decoration: none;
  color: #666;
  font-size: 1.188rem;
  font-weight: 500;
  display: block;
  height: 35px;
}

.nav-link:hover {
  cursor: pointer;
  color: #f00;
  transition: 0.5s;
}
.dropdown {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  background-color: white;
  min-width: 200px; 
  width: 250px;
  opacity: 0;
  animation: dropdown 0.5s forwards;
}

ul.dropdown {
  padding: 20px 10px;
}
.dropdown .nav-link {
  font-size: 1rem;
  min-height: 32px;
  height: fit-content;
}
.subcategories-dropdown {
  height: fit-content;
  width: 250px;
  background-color: white;
  color: #666;
  opacity: 0;
  animation: dropdown 0.5s forwards;
}
ul.subcategories-dropdown {
  padding: 10px 10px;
}
.subcategories-dropdown .nav-link{
  font-size: 0.9rem;
}
@keyframes dropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 770px) {
.menu-list {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 320px;
    row-gap: 25px;
    background: rgb(150, 149, 149);
    padding-top: 140px;
    padding-left: 20px;
    z-index: 2;
    transform: translateX(-100%);
    transition: all 0.5s;
  }
  .menu-list.active {
    transform: translateX(0);
  }

}
