@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
  margin: 0;
  color: rgb(87, 86, 86);
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul,
ol {
  list-style: none;
}
a {
  text-decoration: none;
}
input {
  outline: none;
}
img {
  max-width: 90%;
}

#root {
  height: fit-content;
}

