button:hover {
  cursor: pointer;
}
.button {
  border: 1px solid #999;
  width: 160px;
  height: 35px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;
  background-color: white;
  text-transform: uppercase;
}

.button:hover {
  opacity: 0.8;
  background: #888;
  color: #fff;
}