.burger-block {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 20px;
  left: 20px;
  display: none;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 3;
  transition: all 0.5s linear;
}

.burger-line {
  width: 40px;
  height: 4px;
  background-color: #fff;
  border-radius: 10px;
}

@media (max-width: 770px) {
  .burger-block {
    display: flex;
    transition: 0.3s;
  }
  .active.burger-block div:first-child {
    transform: rotate(45deg) translateX(15px) translateY(12.5px);
  }
  .active.burger-block div:nth-of-type(2) {
    display: none;
  }
  .active.burger-block div:last-child {
    transform: rotate(-45deg);
  }
}