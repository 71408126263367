.layout-background img {
  min-width: 100vw;
  height: 8rem;
}
.banner-title {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 8rem;
}
.banner-title a {
  color: white;
  padding-left: 5px;
}
.products {
  display: flex;
  margin: 3rem 6rem;
}
.products-layout {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.products-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.aside-menu {
  width: 250px;
  height: fit-content;
  /* margin: 4rem; */
}
.aside-menu-nav {
  width: 250px;
}
.category-item {
  background-color: #999;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.category-item a {
  padding: 0 20px 5px;
}
.category-item > a {
  color: white;
}
.subcategory-item {
  background-color: #eee;
  width: 100%;
}
.subcategory li {
  display: flex;
}
.subcategory li:hover {
  background-color: #f00;
}
.subcategory-item a {
  font-size: 0.8rem;
  padding: 10px 20px;
 }
.subcategory-item a:hover {
  color: white;
}

.card-mini {
  width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 1rem;
  cursor: pointer;
}
.img-card-mini-container {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border: rgba(0, 0, 0, 0.1) 1px solid;
  width: 250px;
  height: 250px;
}
.img-card-mini {
  max-width: 70%;
  max-height: 70%;
} 
.img-card-mini:hover {
  transform: scale(1.1);
  transition-property: transform;
  transition-duration: 0.5s;
}
.span-card-mini {
  padding: 5px;
  margin-top: 10px;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
}
.span-card-mini:hover {
  color: #f00;
}
.product-controls {
  background-color: rgb(198, 196, 196);
}
