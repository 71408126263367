.slide {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 0 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
h2 {
  margin-top: 25px;
}
.slide-images {
  margin-top: 30px;
}
.slider-img {
  height: 50vh;
  max-width: 100%;
}
