.arrows-news {
  position: relative;
  padding-top: 10px;
  transform: translateY(-40px);
}
.arrow {
  font-size: 16px;
  color: #fff;
  width: 26px;
  height: 40px;
  background: #999;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-image: url('../../../../assets/icons/arrow.svg');
  position: absolute;
  top: 50%;
  background-size: 20px;
  background-position: 90% center;
  background-repeat: no-repeat;
}
.arrow-news {
  height: 1.3rem;
  width: 1.8rem;  
  position: absolute;
  top: 0%;
  z-index: 10;
  cursor: pointer;
  justify-content: center;
  background-image: url('../../../../assets/icons/arrow.svg');
  background-size: 0.8rem;
  background-position: 50% center;
  background-repeat: no-repeat;
  background-color: #f00;
}
.arrow-prev {
  transform: rotate(180deg);
}
.arrow-next {
  right: 0%;
}
.arrows-news > .arrow-prev {
  left: 3%;
}
.arrows-news > .arrow-next {
  right: 3%;
}