.container {
  display: flex;
  justify-content: space-around;
  min-height: 50vh;
  margin: 3rem 6rem;
  max-width: 100%;
}
@media (max-width: 770px) {
  .container {
    margin: 1rem 2rem
  }
}
@media (max-width: 400px) {
  .container {
    margin: 10px 0 0 0;
  }
}