.nav-products {
  justify-content: space-between;
  width: 100%;
  min-height: 50px;
  color: white;
  display: flex;
  background-color: #f73d3d;
  margin-top: 40px;
}

.nav-products > div {
  width: 50%;
  display: flex;
  align-items: center;
}
.nav-products .active:hover {
  cursor: pointer;
}
.nav-products p {
  margin: 10px;
}
.next {
  justify-content: flex-end;
}
.nav-products-arrow {
  width: 50px;
  font-size: 16px;
  color: #fff;
  height: 100%;
  background: #bb1919;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-size: 20px;
  background-position: 90% center;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/arrow.svg');
}
.nav-products-arrow.previous {
  transform: rotate(180deg);
}
@media (width < 770px) {
  .nav-products {
    height: auto;
  }
  .nav-products>div {
    height: 47%;
    width: 100%;
  }
  .nav-products-arrow {
    height: 75px;
  }
}
@media (width < 500px) {
  .nav-products {
    height: auto;
    flex-wrap: wrap;
  }
}