.previous-product-container {
  margin-top: 20px;
}
.previous-product {
  height: 250px;
}
.previous-product-container h4 {
  background: #999;
  color: #FFF;
  font-size: 18px;
  padding: 12px 0;
  text-align: center;
  line-height: 1;
}
.img-previous-product-container {
  width: 250px;
  height: 200px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.img-previous-product-container img {
  padding: 10px;
}
@keyframes slidein-previous {
  from {
    margin-top: -100%;
  }

  to {
    margin-top: 0%;
  }
}
.previous-product-container span {
  color: black;
  /* width: 100%;
  height: 100%; */
  display: flex;
  justify-content: center;
  font-size: 14px;
  text-align: center; 
  animation-duration: 1s;
  animation-name: slidein-previous;
}
.previous-product-container:hover {
  cursor: pointer;
}
.aside-news {
  width: 22%;
}
.news-category-item {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.aside-news > h4 {
  background: #999;
  font-size: 18px;
  padding: 12px 0;
  text-transform: uppercase;
  color: #FFF;
  text-align: center;
  line-height: 1;
}
.aside-news  p  {
  font-size: 13px;
  color: #1e1e1e;
  line-height: 15px;
  opacity: .73;
  margin-left: 5px
}
.aside-news-title {
  font-size: 16px;
  color: #1e1e1e;
  line-height: 17px;
  margin-bottom: 10px;
  transition: all .3s ease;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.aside-news-title:hover {
  color: #f00;
}
.aside-news-link {
  display: block;
  width: 25%;
}
@media (max-width: 900px) {
  .aside-news {
    display: none;
  }
}