.button-more {
  /* display: inline-block; */
  padding: 0.3rem;
  box-sizing: border-box;
  border: 1px solid #0a0204;
  color: #1e1e1e;
  font-weight: 300;
  opacity: .51;
  border-radius: 1rem;
  transition: all .2s ease;
}
.button-more:hover {
  background: #f00;
  border: 1px solid #f00;
  color: #FFF;
  opacity: 1;
}