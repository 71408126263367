.current-news-container {
  max-width: 70%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.current-news-container > *:not(:first-child) {
  padding-top: 20px;
}
.current-news-container img {
  max-width: 40%;
  object-fit: cover;
}
.current-news-container p {
  line-height: 1.5;
  word-wrap: break-word;
  text-align: justify;
}
.icon-container {
  padding-left: 5px;
}