.pagination {
  margin: 40px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: fit-content;
}

.pagination>p {
  margin-right: 10px;
  margin-top: 10px;
}

.page-button {
  margin-right: 10px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border: 1px solid #e5e5e5;
  transition: all .2s ease;
  margin-bottom: 10px;
}
.page-button.active {
  background: #f00;
  color: #fff;
}
.page-button:hover {
  background-color: #f00;
}
@media (max-width: 770px) {
  .pagination {
    margin: 0;
  }
  .page-button {
    margin-right: 10px;
    width: 32px;
    height: 32px;
    line-height: normal;
  }
}