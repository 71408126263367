
.container-item-content {
  width: 100%;
  margin-left: 20px;
}
.news.container-item-content {
  max-width: 70%;
}
.item-content-miniature {
  display: flex;
  transition: all .3s ease;
  margin: 0 1rem 1rem;
  overflow: hidden;
  align-items: center;
  width: 100%;
  height: 172px;
}
.item-content-miniature:hover {
  box-shadow: 0 9px 21px 0 rgb(0 0 0 / 7%);
  transform: translateY(-10px);
}
.img-container {
  display: flex;
  align-items: center;
  width: 26%;
  height: 114px;
}
.item-content-miniature img {
  width: 100%;
  height: 100%;
}
.news .item-content-miniature img {
  max-width: 70%;
  object-fit: cover;
  height: auto;
 }
.item-content-description {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: 74%;
  height: 114px;
}

.item-content-description h1 {
  font-size: 18px;
  font-weight: 600;
  color: #1e1e1e;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}

.item-content-description p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3;
}
.item-content-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  margin-top: 10px;
  border-radius: 20px;
  font-weight: 700;
  transition: all .2s ease;
}

@media (max-width: 1050px) {
  .item-content-miniature {
    flex-direction: column;
    height: fit-content;
    margin: 1rem auto;
    max-width: 97%;
    }
  /* .item-content-miniature:hover {
    box-shadow: 10px 5px 5px rgb(0 0 0 / 7%);
     transform: translateY(-10px); 
  } */
  .container-item-content {
    margin: 0 10px;
  }
  .img-container {
    justify-content: center;
    width: 75%;
    height: fit-content;
  }
  .item-content-description {
    align-items: center;
    width: auto;
    height: fit-content;
    margin: 10px;
  }
  .item-content-button {
    margin: 10px 0 0 0;
  }
  .news.container-item-content {
    max-width: 100%;
  }
  .item-content-description span {
    text-align: center;
  }
  .item-content-description p {
    max-height: 86px;
    text-align: justify;
    max-width: 90vw;
  }
  .news.container-item-content .item-content-miniature {
    max-height: 350px;
  }
  .news .item-content-miniature img {
    width: 25%;
    min-width: 150px;
  }
}