.product-container {
  display: flex;
  margin: 3rem 6rem;
}
@media (max-width: 770px) {
  .product-container {
    margin: 1rem 2rem
  }
  .product-card-wrapper {
    margin: 1rem 0;
    width: 100%;
  }
}