.page-404 {
  display: flex;
  flex-wrap: wrap;
}
.page-404-info {
  display: flex;
  justify-content: center;
}
.page-404-card-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  background: #f2f2f2;
  margin-top: 3rem;
}
.page-404-card-container > span {
  margin: 2rem;
}
.products-container-404 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 2rem;
}
.page-404-card-container .img-card-mini-container {
  border: none;
  height: 200px;
}
.products-container-404 > .card-mini {
  background-color: white;
}

.products-container-404 .span-card-mini {
  border-top: 1px solid #e5e5e5;
  padding: 1rem;
}