.slider {
  overflow-x: hidden;
}
h2 {
  color: rgb(46, 46, 46);
}
.slider-container {
  width: 100%;
  height: 75vh;
  background: -webkit-linear-gradient(135deg, rgb(204, 209, 210), rgb(239, 241, 240));
  background: -moz-linear-gradient(135deg, rgb(204, 209, 210), rgb(239, 241, 240));
  background: linear-gradient(135deg, rgb(204, 209, 210), rgb(239, 241, 240));
  transition: transform 0.5s ease-in-out;
}
.slide {
  background-size: cover;
}
.slide-list {
  display: flex;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}
.slide-images {
  display: flex; 
  margin: 0 auto;
  max-height: 300px;
  width: 100%;
  object-fit: contain; 
  width: 100%;
  height: 100%;
  display: none;
}

.slide-title {
  text-align: center;
  margin-top: 10px;
  display: none;
}
