@media (max-width: 770px) {
  .banner-title {
    text-align:center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    font-size: smaller;
  }
}