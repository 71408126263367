.industry-image-container {
  display: flex;
  justify-content: center;
}
.industry-image-container img {
  width: 50%;
}
.industry-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
}