input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
.search-bar {
  background-color: inherit;
  border: 0.05rem solid rgb(160, 156, 156);
  border-radius: 0.3rem;
  width: 15rem;
  max-height: 2rem;
  padding: 8px 10px;
  margin: 6px 0;
  box-sizing: border-box;
}
.search-bar:hover {
  border-color: #555;
}
.search-bar:focus {
  border-color: #555;
}
.search-button {
  background-image: url('../../assets/icons/searchicon.png');
  background-size: 20px;
  background-position: 90% center;
  background-repeat: no-repeat;
  padding: 10px;
  position: relative;
  top: -5px;
  left: -30px;
  border: none;
  background-color: transparent;
}
@media (max-width: 770px) {
  .form-search {
    display: none;
  }
}