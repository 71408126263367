.product-card-wrapper {
  margin: 0 2rem;
  width: 80%;
}
.product-card-container {
  display: flex;
}
.product-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 320px;
  min-width: 20rem; 
  border: solid 1px #eae8e8;
  text-align: center
}
.product-card-img-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  flex-direction: column;
}
.product-card-img {
  max-height: 220px;
  transition: 0.5s;
}
.product-card-img:hover {
  width: initial;
  height: initial;
  transform: scale(1.3);
}
.product-details {
  /* height: fit-content; */
  height: 260px;
  padding-top: 10px;
  /* margin-left: 2rem; */
  overflow-y: scroll;
}
.product-additional {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  justify-content: space-between;
  height: 380px;
}
.product-additional h2 {
  font-size: 25px;
  font-weight: 700;
  color: #242424;
}
.product-details p {
  font-size: 16px;
  color: #1e1e1e;
  opacity: .65;
  line-height: 1.7;
  text-overflow: ellipsis;
}
.vk-link {
  width: 100%;
  height: 35px;
  margin-top: 10px
}
.product-controls-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
.link-to-form {
  color: #f00;
  border: 1px solid #f00;
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 35px;
  justify-content: center;
  margin: 20px auto;
  text-transform: uppercase;
  transition: all .2s linear;
  width: 160px;
}
.link-to-form:hover {
  border: 1px solid #031961;
  color: #031961;
  background-color: white;
}
.product-features {
  margin-top: 20px;
 }

.product-features h4 {
  display: inline;
  color: rgb(255, 255, 255);
  background-color: rgb(255, 0, 0);
  font-size: 20px;
  white-space: normal;
}
.product-features-list {
  list-style-type: circle;
  line-height: 1.5;
  margin-left: 20px;
  margin-bottom: 30px;
}

hr {
  margin-top: 5px;
}
@media (max-width: 1200px) {
  .product-card-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .product-additional {
    margin-top: 10px;
  }
  .product-additional>div {
    margin: 0;
  }
  .product-additional h1 {
    margin: 0;
  }
  .product-features>h4 {
    margin-top: 20px;
  }
}
@media (max-width: 360px) {
  .product-card {
    min-width: 280px;
    width: 300px;
  }
}