.dots {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  position: relative;
  z-index: 2;
  margin-top: -50px;
}
.dot {
  background-color: white;
  border-radius: 5px;
  height: 10px;
  margin: 5px;
  width: 10px;
}
.dot:hover {
  cursor: pointer;
}
.dot.selected {
  background-color: #f00;
}
